import React, { useEffect, useRef } from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { GoogleMap } from '../../../utils/googlemaps'
import { useMapaCalorAlertas } from '../../../hooks/estadisticas/useMapaCalorAlertas'

const googleMap = new GoogleMap()

export const MapaCalorAlertas = () => {
  const mapaRef = useRef(null)

  const hookMapaCalorAlertas = useMapaCalorAlertas()

  useEffect(() => {
    (async () => {
      googleMap.inicializarMapa(mapaRef.current)
      await hookMapaCalorAlertas.obtenerJurisdiccion()
      await hookMapaCalorAlertas.obtenerAlertas()
    })()
  },[])

  useEffect(() => {
    hookMapaCalorAlertas.jurisdiccion.length != 0 && googleMap.crearPoligono({listLatLng: hookMapaCalorAlertas.jurisdiccion,opacidadFondo: 0.01})
  },[hookMapaCalorAlertas.jurisdiccion])

  useEffect(() => {
    if(hookMapaCalorAlertas.alertas.length != 0){
      const test = hookMapaCalorAlertas.alertas.map((data,i) => {
          return({
              location: new window.google.maps.LatLng(data.latitudRegistro,data.longitudRegistro),
              weight: 2
          })
      })
      googleMap.crearMapaDeCalor({
        dataMapaCalor: test,
        radio: 50
      })
    } 
  },[hookMapaCalorAlertas.alertas])

  return (
    <>
            <ContenedorParametros
                titulo='Mapa de calor de alertas'
            >   
            </ContenedorParametros>
            
            <div className="containerScroll mt-7 relative top-0 bottom-0 left-0 right-0 contenedor-tabla border">
              <div ref={mapaRef} className='w-full h-full'></div>
            </div>
        </>
  )
}
