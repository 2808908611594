import React from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const Modal = ({
    isOpen, 
    closeModal, 
    action, 
    width, 
    children, 
    title, 
    cerrarAlGuardar = false,
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true},
    clasesModal
}) => {
    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 
    const ancho = `w-${width}`

    return (
    <>  
        {isOpen ? (
            <>
                <Draggable handle="strong">
                    <div className="justify-center items-center flex overflow-hidden fixed inset-0 z-[1050] outline-none focus:outline-none">
                        <div className={`${clasesModal ? clasesModal : ''} relative ${width ? ancho : 'w-auto'} my-6 mx-auto max-w-[90%] lg:max-h-[90%] overflow-auto`} style={{boxShadow: 'rgb(0 0 0) 0px 0px 25px 8px'}}>
                        {/*content*/}
                            <div className=" border-0 shadow-lg relative flex flex-col w-full outline-none focus:outline-none">
                                {/*header*/}
                                <strong className="lg:sticky lg:top-0 z-10">
                                    <div className="divMovibleCabecera bg-[#003478]  flex items-start justify-between p-5 border-b border-solid border-[#4e4e4e] ">
                                        <h3 className="text-xl font-semibold">
                                            {title}
                                        </h3>
                                        <button
                                            className=""
                                            onClick={closeModal}
                                            onTouchEnd={closeModal}
                                            >
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </strong>
                                {/*body*/}
                                <div className="relative px-2 py-3 flex-auto bg-[#2f3134] overflow-auto lg:flex-grow">
                                    { children }
                                </div>
                                {/*footer*/}
                                <div className=" --bg-[#2d2f30] bg-black   flex items-center justify-end px-6 py-4 lg:sticky lg:bottom-0">
                                {validButton.confirm ?
                                    <button
                                    className="min-w-[100px] bg-blue-600 hover:bg-blue-600 text-white active:bg-blue-600 font-bold capitalize text-sm px-4 py-3 rounded-xl shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                    type="button"
                                        onClick={async () => {
                                            mostrarSpinner();
                                            await action();
                                            ocultarSpinner();
                                            // cerrarAlGuardar && closeModal()
                                        }}
                                    >
                                        {
                                            spinner 
                                            ? <Spinner spinner={spinner.toString()}></Spinner>
                                            : <span>{textButtons.confirm}</span>
                                        }
                                    </button>
                                    : ''
                                }
                                {validButton.denied ?
                                    <button
                                    className="bg-gray-700 text-white-500 hover:bg-stone-600 rounded-xl background-transparent font-bold capitalize px-6 py-3 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150 text-xs"
                                    type="button"
                                    onClick={closeModal}
                                    >
                                        {textButtons.denied} 
                                    </button>
                                    : ''
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="opacity-25 fixed inset-0 z-[1040] bg-black"></div> */}
                </Draggable>
            </>
        ) : null}
        </>
    );
}