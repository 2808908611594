import React, { useState } from 'react'
import WavesurferPlayer from '@wavesurfer/react'

export const Waveform = ({sonidoAlerta}) => {
  const [wavesurfer, setWavesurfer] = useState(null)
  const [isPlaying, setIsPlaying] = useState(false)

  const onReady = (ws) => {
    setWavesurfer(ws)
    setIsPlaying(false)
  }

  const onPlayPause = () => {
    wavesurfer && wavesurfer.playPause()
  }

  return (
    <>
      <div className='flex gap-2 items-center justify-center'>
        <div className='flex-grow'>
          <WavesurferPlayer
            height={100}
            waveColor="violet"
            url={sonidoAlerta}
            onReady={onReady}
            onPlay={() => setIsPlaying(true)}
            onPause={() => setIsPlaying(false)}
          />
        </div>
        <button onClick={onPlayPause} className='w-[40px] h-[40px] rounded-full border'>
          {isPlaying ? <i className="fa fa-pause" aria-hidden="true"></i> : <i className="fa fa-play" aria-hidden="true"></i>}
        </button>
      </div>
    </>
  )
}
