import React, { useEffect, createContext, useReducer } from "react";
import { UserReducers } from '../reducers/UserReducers'
import { UserTypes } from "../types/UserTypes"

// import { useEncriptado } from "../../hooks/useEncriptado";

export const UserContext = createContext()

export function UserProvider(props) {
    const decifrado = atob(localStorage.getItem('pm-session') || '')
    const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);

    const decifradoPuntos = atob(localStorage.getItem('pm-poligonos') || '')
    const localPuntos = decifradoPuntos === '' ? {} : JSON.parse(decifradoPuntos);

    const initialUserState = {
        nomUsuario: datosSession.nomUsuario || '',
        uid: datosSession.uid || '',
        token: datosSession.token || datosSession.refresh_token ||  '',
        tokenExpire: datosSession.tokenExpire || 0,
        tokenType : datosSession.tokenType || '',
        codUsuarioTipo: datosSession.codUsuarioTipo || 1,       // POR DEFECTO LA PRIMERA ALERTA
        codSonidoAlerta: datosSession.codSonidoAlerta || 0,
        codSesion: datosSession.codSesion || 0,
        nombre:datosSession.nombre,
        apellidoPaterno:datosSession.apellidoPaterno,
        apellidoMaterno:datosSession.apellidoMaterno,
        telefono:datosSession.telefono,
        numeroDocumento:datosSession.numeroDocumento,
        menus: datosSession.menus || [],
        permisos: datosSession.permisos || [],
        urlFoto: datosSession.urlFoto || '',
        codEntidad: datosSession.codEntidad,
        nomEntidad: datosSession.nomEntidad,
        nomEntidadPadre: datosSession.nomEntidadPadre,
    }

    const [ state, dispatch ] = useReducer(UserReducers, initialUserState)

    const signIn = (objSession) => {
        var cifrado = btoa(JSON.stringify(objSession));
        localStorage.setItem('pm-session', cifrado);


        dispatch({type: UserTypes.SIGN_IN, payload: objSession})
    }
    const signOut = () => {
        localStorage.clear();
        
        dispatch({type: UserTypes.SIGN_OUT})
    }

    const cambiarZona = (zona, key, puntos, codigosAReiniciar) => {
        let nuevoObj = {
            'puntosPoligonoDepartamento': {
                puntosPoligonoProvincia: '',
                puntosPoligonoDistrito: ''
            },
            'puntosPoligonoProvincia': {
                puntosPoligonoDistrito: ''
            },
            'puntosPoligonoDistrito': {
            }
        };

        const nuevosPuntos = {
            ...state.poligonosInicial,
            [key]: puntos,
            ...nuevoObj[key]
        }; 
        dispatch({type: UserTypes.CAMBIAR_UBICACION, payload: { zona, nuevosPuntos, codigosAReiniciar }})
    }
    
    const cargarDatosPoligonos = (puntosPoligonos, zona) => {
        dispatch({type: UserTypes.CAMBIAR_PUNTOS_POLIGONOS, payload: { puntos: { ...puntosPoligonos }, zona: zona } })
    }
    
    const editarLocalizacionStorage = (codigos) => {
        const decifrado = atob(localStorage.getItem('pm-session') || '')
        const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);
        const nuevosDatos = {
            ...datosSession,
            codDepartamento: codigos.codDepartamento || 0,
            codDistrito: codigos.codDistrito || 0,
            codProvincia: codigos.codProvincia || 0,
            zona: state.zona
        }

        localStorage.setItem('pm-session', btoa(JSON.stringify(nuevosDatos)));
        localStorage.setItem('pm-poligonos', btoa(JSON.stringify(state.poligonosInicial)))
        dispatch({ type: UserTypes.CAMBIAR_CONFIGURACION })
    }

    const editarSonidoLocalStorage = (codSonidoAlerta) => {
        const decifrado = atob(localStorage.getItem('pm-session') || '')
        const datosSession = decifrado == '' ? {} : JSON.parse(decifrado);
        const nuevosDatos = {
            ...datosSession,
            codSonidoAlerta: codSonidoAlerta
        }

        localStorage.setItem('pm-session', btoa(JSON.stringify(nuevosDatos)));
        dispatch({ type: UserTypes.CAMBIAR_CODIGO_ALERTA, payload : {codSonidoAlerta: codSonidoAlerta} })
    }

    return (
        <UserContext.Provider value={{stateUser: state, signIn, signOut, cambiarZona, cargarDatosPoligonos, editarLocalizacionStorage, editarSonidoLocalStorage}}>
            {props.children}
        </UserContext.Provider>
    )
}