import React, { useContext, useEffect, useState } from 'react'
import { UserContext } from '../../context/provider/UserProvider'
import { AuthFetch } from '../../services/api'
import { notify } from '../../utils/utils'

export const useMapaCalorAlertas = () => {

  const {stateUser} = useContext(UserContext)

  const codEntidad = stateUser.codEntidad

  const [jurisdiccion,setJurisdiccion] = useState([])
  const [alertas,setAlertas] = useState([])

  useEffect(() => {
    console.log("stateUser -> ",stateUser)
  },[])

  const obtenerJurisdiccion = async () => {
    const response = await AuthFetch({
      url: `${process.env.REACT_APP_PLAMIN_API}/api/Entities/jurisdictionByEntity?CodEntidad=${codEntidad}`
    })

    if(response.isValid){
      setJurisdiccion(
        response.content[0].poligonoJurisdiccion
        .split(',')
        .map(data => ({lat: Number(data.split(';')[0]), lng: Number(data.split(';')[1])}))
      )
    }else{
      notify(response.exceptions[0].description,'error')
    }
  }

  const obtenerAlertas = async () => {
    const response = await AuthFetch({
      url: `${process.env.REACT_APP_PLAMIN_API}/api/alerts?CodEstado=0`
    })

    if(response.isValid){
      setAlertas(response.content)
    }else{
      notify(response.exceptions[0].description,'error')
    }
  }
  
  return {
    obtenerJurisdiccion,
    jurisdiccion,
    obtenerAlertas,
    alertas
  }
}
