import React from "react";
import { Spinner } from '../utils/Spinner';
import { useSpinner } from "../../../hooks/useSpinner";
import Draggable from "react-draggable";

export const ModalAsignacion = ({
    isOpen, 
    closeModal, 
    action, 
    children, 
    title, 
    textButtons = { confirm: 'Guardar', denied: 'Cancelar' }, 
    validButton = { confirm: true , denied: true },
}) => {

    const [ spinner, mostrarSpinner, ocultarSpinner ] = useSpinner(); 

    return (
        <>
            {isOpen && (
                <Draggable
                    handle="strong"
                    defaultPosition={{ x: window.innerWidth - 500, y: 100 }} // Centramos el modal al inicio
                >
                    <div
                        className="fixed inset-0 flex items-center justify-center z-[1050] outline-none"
                        style={{ width: 'fit-content',height: 'fit-content' }}  // Ajuste de ancho automático
                    >
                        <div className="relative w-full max-w-lg mx-auto"> {/* Tamaño del modal ajustado */}
                            {/* content */}
                            <div className="rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none">
                                {/* header */}
                                <strong>
                                    <div className="divMovibleCabecera bg-[#003478] flex items-start justify-between p-5 border-b border-solid border-[#4e4e4e] rounded-t">
                                        <h3 className="text-xl font-semibold">
                                            {title}
                                        </h3>
                                        <button onClick={closeModal} className="">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </strong>
                                {/* body */}
                                <div className="relative p-6 flex-auto bg-[#2f3134]">
                                    {children}
                                </div>
                                {/* footer */}
                                <div className="bg-[#2d2f30] flex items-center justify-end p-6 rounded-b text-left w-full">
                                    {validButton.confirm && (
                                        <button
                                            className="min-w-[100px] bg-blue-500 hover:bg-blue-600 text-white font-bold px-4 py-2 rounded shadow mr-1 mb-1 transition-all duration-150 text-xs"
                                            type="button"
                                            onClick={async () => {
                                                mostrarSpinner();
                                                await action();
                                                ocultarSpinner();
                                            }}
                                        >
                                            {spinner ? <Spinner spinner={spinner.toString()} /> : <span>{textButtons.confirm}</span>}
                                        </button>
                                    )}
                                    {validButton.denied && (
                                        <button
                                            className="text-white-500 hover:bg-stone-600 rounded font-bold px-6 py-2 mr-1 mb-1 transition-all duration-150 text-xs"
                                            type="button"
                                            onClick={closeModal}
                                        >
                                            {textButtons.denied}
                                        </button>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </Draggable>
            )}
        </>
    );
};
