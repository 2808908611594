import React from 'react'
import { ContenedorParametros } from '../../components/utils/ContenedorParametros'
import { Waveform } from '../../components/forms/Waveform'
import { useSound } from '../../../context/provider/SoundProvider'
import sonido_1 from '../../../assets/sound/sonidos-alertas/sonido_1.mp3'
import sonido_2 from '../../../assets/sound/sonidos-alertas/sonido_2.mp3'
import sonido_3 from '../../../assets/sound/sonidos-alertas/sonido_3.mp3'
import sonido_4 from '../../../assets/sound/sonidos-alertas/sonido_4.mp3'
import { notify } from '../../../utils/utils'
import { useSonidos } from '../../../hooks/registros/useSonidos'

export const SonidosAlertas = () => {
  const {alertas , sonidoAlertaActual , codigoAlertaActual , actualizarSonidoAlerta} = useSound()   // GUARDA EL SONIDO ELEGIDO EN EL CONTEXTO DE LAS ALERTAS

  const hookSonidos = useSonidos()

  const actualizarAlertaSonido = async (codigoAlerta) => {
    const response = await hookSonidos.actualizarSonidoSesion(codigoAlerta)

    notify(response.DesResultado , response.CodResultado == 1 ? 'success' : 'error')

    if(response.CodResultado == 1){
      actualizarSonidoAlerta(codigoAlerta)
    }
  }

  return (
    <>
      <ContenedorParametros titulo='Sonidos de alertas'></ContenedorParametros>
      <div className='flex-grow overflow-auto containerScroll'>
        <table className='table'>
          <thead>
            <tr>
              <th className='text-left'>Nº</th>
              <th className='text-left'>NOMBRE</th>
              <th className='text-left'>SONIDO</th>
              <th className='text-left w-[200px]'></th>
            </tr>
          </thead>
          <tbody>
            {
              alertas.map((data,i) => (
                <tr key={i+1}>
                  <td>{i+1}</td>
                  <td>{data.nomAlerta}</td>
                  <td><Waveform sonidoAlerta={data.sonidoAlerta} /></td>
                  <td className='text-right'>
                    {
                      codigoAlertaActual == data.codAlerta
                      ? <></>
                      : <button 
                          onClick={() => {
                            actualizarAlertaSonido(data.codAlerta)
                          }} 
                          className='w-[35px] h-[35px] rounded bg-green-600 text-xs'>
                          <i className='fas fa-check'></i>
                        </button>
                    }
                  </td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </div>
    </>
  )
}
