import React, { useState } from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

export default function Pies({
    nombres = [],
    cantidades = []
}) {
    // PARA UN GRAFICO DE PRUEBA
    const labelsTest = ['Sin Información'];
    const cantidadesTest = [1];

    const totalMuestras = cantidades.reduce((acc, cantidad) => acc + cantidad, 0) || 1;

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: 'right',
                align: 'end',
                labels: {
                    boxWidth: 20,
                    padding: 20,
                    usePointStyle: true,
                    textAlign: 'left',
                    maxWidth: 200,  
                },
            },
            tooltip: {
                callbacks: {
                    label: function(context) {
                        let label = context.label || '';
                        if (label) {
                            label += ': ';
                        }
                        label += context.raw;
                        return label;
                    },
                    afterBody: function() {
                        return `Total: ${totalMuestras}`;
                    }
                }
            },
            datalabels: {
                display: true,
                color: 'white',
                font: {
                    weight: 'bold',
                    size: '14px'
                },
                formatter: function(value, context) {
                    let percentage = (value / totalMuestras * 100).toFixed(2) + '%';
                    return percentage;
                }
            }
        },
    };

    const data = {
        labels: nombres.length !== 0 ? nombres : labelsTest,
        datasets: [
            {
                label: 'Cantidad',
                data: cantidades.length !== 0 ? cantidades : cantidadesTest,
                backgroundColor: [
                    'rgba(75, 192, 192, 0.5)',
                    'rgba(255, 99, 132, 0.5)',
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(255, 206, 86, 0.5)',
                    'rgba(153, 102, 255, 0.5)',
                    'rgba(255, 159, 64, 0.5)',
                ],
                borderColor: [
                    'rgba(75, 192, 192, 1)',
                    'rgba(255, 99, 132, 1)',
                    'rgba(54, 162, 235, 1)',
                    'rgba(255, 206, 86, 1)',
                    'rgba(153, 102, 255, 1)',
                    'rgba(255, 159, 64, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    return <Pie data={data} options={options} />;
}
