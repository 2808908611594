import React, { useContext, useState } from 'react'
import { AuthFetch } from '../../services/api'
import { notify, urlApiPlamin } from '../../utils/utils'
import { UserContext } from '../../context/provider/UserProvider'
export const useSonidos = () => {

  const {stateUser} = useContext(UserContext)

  const [sonidos,setSonidos] = useState([])

  const listarSonidos = async () => {
    const response = await AuthFetch({
      url: urlApiPlamin + '/Sounds/getSounds'
    })
    if(response.isValid){
      setSonidos(response.content)
    }else{
      notify(response.exceptions[0].description,'error')
    }
  }

  const actualizarSonidoSesion = async (codSonidoAlerta) => {
    const response = await AuthFetch({
      url: urlApiPlamin + '/Sounds/assignSound',
      method: 'PUT',
      body: JSON.stringify({
        "uidAccion": stateUser.uid,
        "codSonidoAlerta": codSonidoAlerta
      })
    })

    return{
      CodResultado : response.isValid ? 1 : 0,
      DesResultado: response.isValid ? response.content : response.exceptions[0].description
    }
  }
  
  return {
    listarSonidos,
    sonidos,
    actualizarSonidoSesion
  }
}
