import React, { createContext, useContext, useEffect, useState } from 'react';
import sonideDefault from '../../assets/sound/sonidos-alertas/sonido_1.mp3'
import { UserContext } from './UserProvider';
import Alerta_1 from '../../assets/sound/sonidos-alertas/sonido_1.mp3'
import Alerta_2 from '../../assets/sound/sonidos-alertas/sonido_2.mp3'
import Alerta_3 from '../../assets/sound/sonidos-alertas/sonido_3.mp3'
import Alerta_4 from '../../assets/sound/sonidos-alertas/sonido_4.mp3'

// Creamos el contexto
const SoundContext = createContext();

// Hook para acceder al contexto de sonido
export const useSound = () => useContext(SoundContext);

// Proveedor del contexto para envolver la aplicación
export const SoundProvider = ({ children }) => {
    const alertas = [
        {
            nomAlerta: 'Alerta_1',
            codAlerta: 1,
            sonidoAlerta: Alerta_1
        },
        {
            nomAlerta: 'Alerta_2',
            codAlerta: 2,
            sonidoAlerta: Alerta_2
        },
        {
            nomAlerta: 'Alerta_3',
            codAlerta: 3,
            sonidoAlerta: Alerta_3
        },
        {
            nomAlerta: 'Alerta_4',
            codAlerta: 4,
            sonidoAlerta: Alerta_4
        }
    ]

    const {stateUser , editarSonidoLocalStorage} = useContext(UserContext)
    const codigoAlertaSesion = stateUser.codSonidoAlerta
    const [codigoAlertaActual, setCodigoAlertaActual] = useState(codigoAlertaSesion || 1)
    const [sonidoAlertaActual, setSonidoAlertaActual] = useState(alertas.find(elem => elem.codAlerta == codigoAlertaActual).sonidoAlerta)
    
    useEffect(() => {
        if(stateUser.codSonidoAlerta){
            setCodigoAlertaActual(stateUser.codSonidoAlerta)
            setSonidoAlertaActual(alertas.find(elem => elem.codAlerta == stateUser.codSonidoAlerta).sonidoAlerta)
        }
    },[stateUser])

    
    const actualizarSonidoAlerta = (codigoAlerta) => {
        editarSonidoLocalStorage(codigoAlerta)
    }

    return (
        <SoundContext.Provider value={{ alertas ,sonidoAlertaActual , codigoAlertaActual , actualizarSonidoAlerta}}>
            {children}
        </SoundContext.Provider>
    );
};
