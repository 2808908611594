import { Line } from 'react-chartjs-2';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React, { useState, useEffect, useRef } from 'react';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
    ChartDataLabels
);

export default function LinesChart({ datos }) {
    const [tooltipData, setTooltipData] = useState({ open: false, data: null });
    const [valorMaximo, setValorMaximo] = useState({ valor: 0, horario: '' });
    const chartRef = useRef(null);

    // Ordena los datos por rangoHorario
    const datosOrdenados = [...datos].sort((a, b) => a.rangoHorario.localeCompare(b.rangoHorario));

    // Extrae las etiquetas del eje x (rangoHorario) y los valores del eje y (cantidad)
    const etiquetas = datosOrdenados.map((obj) => obj.rangoHorario);
    const cantidades = datosOrdenados.map((obj) => obj.cantidad);

    // Calcula el valor máximo de cantidad y su rango horario
    useEffect(() => {
        const maxCantidad = Math.max(...cantidades);
        const maxHorario = datosOrdenados.find((obj) => obj.cantidad === maxCantidad)?.rangoHorario || '';
        setValorMaximo({ valor: maxCantidad, horario: maxHorario });
    }, [datos]);

    const onChartClick = (event) => {
        const points = chartRef.current.getElementsAtEventForMode(event, 'nearest', { intersect: true }, true);

        if (points.length) {
            const firstPoint = points[0];
            const label = chartRef.current.data.labels[firstPoint.index];
            const value = chartRef.current.data.datasets[firstPoint.datasetIndex].data[firstPoint.index];
            
            setTooltipData({ open: true, data: { label, value } });
        } else {
            setTooltipData({ open: false, data: null });
        }
    };

    // Configuración de datos para el gráfico
    const data = {
        labels: etiquetas,
        datasets: [
            {
                data: cantidades,
                tension: 0.3,
                fill: false,
                borderColor: 'rgba(80,190,255,1)',
                backgroundColor: 'rgba(80,190,255,0.5)',
                pointRadius: 4,
                pointBorderColor: 'rgba(80,190,255,1)',
                pointBackgroundColor: 'rgba(80,195,234,1)',
            },
        ],
    };

    // Configuración de opciones para el gráfico
    const opciones = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                min: Math.min(...cantidades) - 10,
                max: valorMaximo.valor + 50,
                ticks: { color: 'rgb(255, 255, 255)' }
            },
            x: {
                ticks: { color: 'rgb(255, 255, 255)' }
            }
        },
        plugins: {
            tooltip: {
                enabled: true,
                mode: 'point',
                callbacks: {
                    label: function (context) {
                        let label = context.dataset.label || '';
                        if (label) {
                            label += ': ';
                        }
                        if (context.parsed.y !== null) {
                            label += `Procesos: ${context.parsed.y}`;
                        }
                        return label;
                    }
                },
                titleColor: '#FFFFFF', // Cambia el color del título del tooltip a blanco
                bodyColor: '#FFFFFF',  // Cambia el color del texto del tooltip a blanco
            },
            legend: {
                display: false,
            },
            title: {
                display: true,
                text: `Rango horario`,
                color: 'white',
                font: {
                    size: 25
                }
            },
            datalabels: {
                color: 'white', // Cambia el color de los números sobre cada punto a blanco
                align: 'top',
                formatter: (value) => `${value}`,
                font: {
                    weight: 'bold',
                    size: 10,
                }
            }
        }
    };
    
    return (
            <Line ref={chartRef} data={data} options={opciones} onClick={onChartClick} />
    );
}




// --------------------------- DOCUMENTACION DATA ------------------------------------
// labels: Un array de strings que define las etiquetas para el eje X del gráfico.
// datasets: Un array de objetos que representa los conjuntos de datos del gráfico. Cada objeto puede tener las siguientes propiedades:
// label: El nombre o etiqueta del conjunto de datos.
// data: Un array de valores numéricos que representan los datos del conjunto.
// backgroundColor: El color de fondo del área debajo de la línea.
// borderColor: El color del borde de la línea.
// borderWidth: El ancho del borde de la línea.
// borderDash: Un array que define el patrón de guiones y espacios para el borde de la línea.
// borderDashOffset: Un número que especifica el desplazamiento del patrón de guiones y espacios del borde de la línea.
// borderCapStyle: El estilo de los extremos del borde de la línea.
// borderJoinStyle: El estilo de las conexiones entre los segmentos del borde de la línea.
// cubicInterpolationMode: El modo de interpolación cúbica utilizado para suavizar la línea.
// fill: Un booleano que indica si se debe rellenar el área debajo de la línea.
// lineTension: Un número que controla la suavidad de las curvas en el gráfico.
// pointBorderColor: El color del borde de los puntos en la línea.
// pointBackgroundColor: El color de fondo de los puntos en la línea.
// pointBorderWidth: El ancho del borde de los puntos en la línea.
// pointRadius: El radio de los puntos en la línea.
// pointHoverRadius: El radio de los puntos cuando se pasa el cursor sobre ellos.
// pointHitRadius: El radio de los puntos utilizados para la detección de eventos.
// pointHoverBackgroundColor: El color de fondo de los puntos cuando se pasa el cursor sobre ellos.
// pointHoverBorderColor: El color del borde de los puntos cuando se pasa el cursor sobre ellos.
// pointHoverBorderWidth: El ancho del borde de los puntos cuando se pasa el cursor sobre ellos.
// pointStyle: El estilo de los puntos en la línea.
// showLine: Un booleano que indica si se debe mostrar la línea o solo los puntos.
// spanGaps: Un booleano que indica si se deben dibujar líneas entre los datos faltantes o espacios vacíos.
// steppedLine: Un string que indica si la línea debe ser escalonada y cómo (por ejemplo, 'before', 'after', 'middle').

// --------EJEMPLO
// const data = {
//     labels: ['Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio'],
//     datasets: [
//         {
//             label: 'Ingresos',
//             data: [1000, 1500, 1200, 1800, 2000, 1600],
//             backgroundColor: 'rgba(75, 192, 192, 0.2)',
//             borderColor: 'rgba(75, 192, 192, 1)',
//             borderWidth: 2,
//             borderDash: [5, 5],
//             borderDashOffset: 0,
//             borderCapStyle: 'butt',
//             borderJoinStyle: 'miter',
//             cubicInterpolationMode: 'default',
//             fill: true,
//             lineTension: 0.4,
//             pointBorderColor: 'rgba(75, 192, 192, 1)',
//             pointBackgroundColor: '#fff',
//             pointBorderWidth: 1,
//             pointRadius: 4,
//             pointHoverRadius: 6,
//             pointHitRadius: 8,
//             pointHoverBackgroundColor: 'rgba(75, 192, 192, 1)',
//             pointHoverBorderColor: '#fff',
//             pointHoverBorderWidth: 2,
//             showLine: true,
//             spanGaps: false,
//             steppedLine: false,
//         },

// ----------------------------------DOCUMENTACION DE OPCIONES ----------------------------------------
// responsive: Un booleano que indica si el gráfico debe ser responsivo y ajustarse al tamaño del contenedor. Si se establece en true, el gráfico se redimensionará automáticamente según el tamaño del contenedor que lo contiene.
// maintainAspectRatio: Un booleano que indica si se debe mantener el aspecto del gráfico al cambiar de tamaño. Si se establece en false, el gráfico puede cambiar su aspecto al ajustarse a diferentes tamaños de contenedor.
// scales: Define las opciones de escala para los ejes x e y del gráfico.
// x: Propiedades personalizadas para el eje x del gráfico.
// display: Un booleano que indica si se debe mostrar el eje x.
// title: Configuración del título del eje x.
// display: Un booleano que indica si se debe mostrar el título del eje x.
// text: El texto del título del eje x.
// color: El color del título del eje x.
// font: Configuración de fuente para el título del eje x, como el tamaño y el peso de la fuente.
// grid: Configuración de las líneas de la cuadrícula para el eje x.
// display: Un booleano que indica si se deben mostrar las líneas de la cuadrícula para el eje x.
// borderColor: El color del borde de las líneas de la cuadrícula para el eje x.
// borderWidth: El ancho del borde de las líneas de la cuadrícula para el eje x.
// ticks: Configuración de los ticks (marcas) del eje x.
// color: El color de los ticks del eje x.
// font: Configuración de fuente para los ticks del eje x, como el tamaño de la fuente.
// y: Propiedades personalizadas para el eje y del gráfico.
// display: Un booleano que indica si se debe mostrar el eje y.
// title: Configuración del título del eje y.
// display: Un booleano que indica si se debe mostrar el título del eje y.
// text: El texto del título del eje y.
// color: El color del título del eje y.
// font: Configuración de fuente para el título del eje y, como el tamaño y el peso de la fuente.
// grid: Configuración de las líneas de la cuadrícula para el eje y.
// display: Un booleano que indica si se deben mostrar las líneas de la cuadrícula para el eje y.
// borderColor: El color del borde de las líneas de la cuadrícula para el eje y.
// borderWidth: El ancho del borde de las líneas de la cuadrícula para el eje y.
// ticks: Configuración de los ticks (marcas) del eje y.
// color: El color de los ticks del eje y.
// font: Configuración de fuente para los ticks del eje y, como el tamaño de la fuente.
// beginAtZero: Un booleano que indica si el eje y debe comenzar en cero.
// stepSize: El tamaño del paso (intervalo) entre los ticks del eje y.
// plugins: Define las opciones de los complementos utilizados en el gráfico.
// title: Configuración del título del gráfico.
// display: Un booleano que indica si se debe mostrar el título del gráfico.
// text: El texto del título del gráfico.
// color: El color del título del gráfico.
// font: Configuración de fuente para el título del gráfico, como el tamaño y el peso de la fuente.
// padding: El espaciado interno (padding) alrededor del título del gráfico.
// legend: Configuración de la leyenda del gráfico.
// display: Un booleano que indica si se debe mostrar la leyenda.
// position: La posición de la leyenda (por ejemplo, 'top', 'bottom', 'left', 'right').
// align: La alineación de la leyenda (por ejemplo, 'center', 'start', 'end').
// labels: Configuración de las etiquetas de la leyenda, como el color y la configuración de fuente.
// layout: Configuración del diseño del gráfico.
// padding: El espaciado interno (padding) alrededor del gráfico.
// elements: Propiedades personalizadas para los elementos del gráfico, como los puntos y las líneas.
// point: Configuración de los puntos del gráfico.
// radius: El radio de los puntos.
// borderWidth: El ancho del borde de los puntos.
// backgroundColor: El color de fondo de los puntos.
// borderColor: El color del borde de los puntos.
// line: Configuración de las líneas del gráfico.
// borderWidth: El ancho del borde de las líneas.
// borderColor: El color del borde de las líneas.
// borderDash: Un array que define el patrón de guiones y espacios para las líneas.
// tension: La suavidad de las curvas en las líneas.

// ----------EJEMPLO
// const opciones = {
//     responsive: true,
//     maintainAspectRatio: false,
//     scales: {
//       x: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Meses',
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         grid: {
//           display: true,
//           borderColor: 'rgba(0, 0, 0, 0.1)',
//           borderWidth: 1,
//         },
//         ticks: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//         },
//       },
//       y: {
//         display: true,
//         title: {
//           display: true,
//           text: 'Ingresos',
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 14,
//             weight: 'bold',
//           },
//         },
//         grid: {
//           display: true,
//           borderColor: 'rgba(0, 0, 0, 0.1)',
//           borderWidth: 1,
//         },
//         ticks: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//           beginAtZero: true,
//           stepSize: 500,
//         },
//       },
//     },
//     plugins: {
//       title: {
//         display: true,
//         text: 'Gráfico de Ingresos Mensuales',
//         color: 'rgba(75, 192, 192, 1)',
//         font: {
//           size: 16,
//           weight: 'bold',
//           family: 'Arial',
//         },
//         padding: {
//           top: 10,
//           bottom: 10,
//         },
//       },
//       legend: {
//         display: true,
//         position: 'top',
//         align: 'center',
//         labels: {
//           color: 'rgba(75, 192, 192, 1)',
//           font: {
//             size: 12,
//           },
//         },
//       },
//     },
//     layout: {
//       padding: {
//         left: 20,
//         right: 20,
//         top: 20,
//         bottom: 20,
//       },
//     },
//     elements: {
//       point: {
//         radius: 5,
//         borderWidth: 2,
//         backgroundColor: 'rgba(75, 192, 192, 1)',
//         borderColor: 'rgba(75, 192, 192, 1)',
//       },
//       line: {
//         borderWidth: 2,
//         borderColor: 'rgba(75, 192, 192, 1)',
//         borderDash: [5, 5],
//         tension: 0.3,
//       },
//     },
//   };

