import { useState, useEffect, useContext } from 'react';
import { notify } from '../../utils/utils';
import { AuthFetch } from '../../services/api';
import { UserContext } from '../../context/provider/UserProvider';
import { useModal } from '../useModal';

const urlBase = process.env.REACT_APP_PLAMIN_API + "/api/Alerts";
const urlBaseSeguimiento = process.env.REACT_APP_PLAMIN_API + "/api/Alerts/tracking";

export const useSeguimientoAlertas = () => {
    const seguimientoAlertaDefault = {
        codAlerta: 0,
        mensajeAlertaAtencion: "",
        codUsuarioAccion: 0
    }
    
    const [ seguimientoAlertas, setSeguimientoAlertas ] = useState([]);
    const [ seguimientoAlerta, setSeguimientoAlerta ] = useState(seguimientoAlertaDefault);
    const [ isOpenModalSeguimiento, openModalSeguimiento, closeModalSeguimiento ] = useModal()
    const {stateUser } = useContext(UserContext)

    const listarSeguimientoAlerta = async (codAlerta) => {
        editarValorSeguimientoAlerta("codAlerta",codAlerta) //guarda el codalerta en el array
        editarValorSeguimientoAlerta('mensajeAlertaAtencion','')    //limpia el textarea del modal
        const response = await AuthFetch({
            url: urlBaseSeguimiento + '?'+ new URLSearchParams({
                codAlerta : codAlerta
            })
        });
        if (response.isValid) {
            setSeguimientoAlertas(response.content);
        } else {
            notify(response.content, 'error');
        }
        openModalSeguimiento()
    }

    const guardarSeguimientoAlerta = async () => {
        if (seguimientoAlerta.mensajeAlertaAtencion === ''){
            notify('Ingrese un comentario válido', 'error' )
            return
        }
        const esGuardar = seguimientoAlerta.codAlertaAtencion <= 0;
        const response = await AuthFetch({
            url: urlBaseSeguimiento,
            method:'POST',
            body: JSON.stringify({
                codAlerta: seguimientoAlerta.codAlerta,
                mensajeAlertaAtencion: seguimientoAlerta.mensajeAlertaAtencion,
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            await listarSeguimientoAlerta(seguimientoAlerta.codAlerta);
        }else{
            notify('Ocurrio un error al registrar el comentario','error')
        }
    }

    const eliminarSeguimiento = async (codSeguimientoEliminar) => {
        const response = await AuthFetch({
            url: urlBaseSeguimiento,
            method:'DELETE',
            body: JSON.stringify({
                codAlertaAtencion: codSeguimientoEliminar,
                motivoEliminacion:seguimientoAlerta.motivoEliminacion,
            })
        });
        notify(response.content, response.isValid ? 'success' : 'error');
        if (response.isValid) {
            await listarSeguimientoAlerta(seguimientoAlerta.codAlerta);
        }
    }

    

    const finalizarAlerta = async () => {
        if(!seguimientoAlerta.codEstadoIntensidadAlerta){
            notify("Debe calificar la alerta antes de finalizarla","error")
            return
        }
        // return
        const response = await AuthFetch({
            url: urlBase + '/close',
            method:'PUT',
            body: JSON.stringify({
                codAlerta: seguimientoAlerta.codAlerta,
                codEstadoIntendidadAlerta: seguimientoAlerta.codEstadoIntensidadAlerta,      //asi mal escrito esta en la api
            })
        });
        if (response.isValid) {
            notify(response.content,'success')
            closeModalSeguimiento()
        }else{
            notify(response.content,'error')
        }
    }

    const editarValorSeguimientoAlerta = (key, value) => {
        setSeguimientoAlerta(seguimientoAlerta => {
            return {
                ...seguimientoAlerta,
                [key]: value
            }
        });
    }
    
    return { 
        seguimientoAlertas, 
        listarSeguimientoAlerta,
        guardarSeguimientoAlerta,
        seguimientoAlerta,
        editarValorSeguimientoAlerta,
        isOpenModalSeguimiento,
        openModalSeguimiento,
        closeModalSeguimiento,
        eliminarSeguimiento,

        finalizarAlerta
    }
}