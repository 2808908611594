import React, { useRef, useContext, useMemo, useEffect, useState } from 'react';
import { UserContext } from '../../../../context/provider/UserProvider';
import { AuthFetch } from '../../../../services/api';
import { notify } from '../../../../utils/utils';


export const MapaAlertas = ({ googleMap }) => {
    const { stateUser } = useContext(UserContext);
    const mapDiv = useRef();

    useEffect(() => {
        googleMap && googleMap.inicializarMapa(mapDiv.current);
    }, []);


    return (
        <div ref={mapDiv} className='w-full h-full'></div>
    )
}